/* eslint-disable array-callback-return */
import React from 'react';
import { Typography, Grid, Card } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Team = () => {
    const data = useStaticQuery(graphql`
        query teamData {
            allTeamJson {
                edges {
                    node {
                        Name
                        Designation
                        alt
                        teams {
                            childImageSharp {
                                gatsbyImageData(width: 1080, height: 1092)
                            }
                        }
                    }
                }
            }
        }
    `);
    const TeamData = (data) => {
        const TeamArray = [];
        data.allTeamJson.edges.map((item) => {
            const image = getImage(item.node.teams);

            TeamArray.push(
                <Grid item xs={2} sm={4} md={2} sx={{ pt: '0.1rem', pb: '1.5rem' }}>
                    <Grid classes="imageCenter">
                        <Card variant="Avatar">
                            <div>
                                <GatsbyImage
                                    image={image}
                                    alt={'testimage'}
                                    imgStyle={{ borderRadius: '100%' }}
                                />
                            </div>
                        </Card>
                    </Grid>
                    <Typography variant="name">{item.node.Name}</Typography>
                    <Typography variant="designation">{item.node.Designation}</Typography>
                </Grid>
            );
        });
        return TeamArray;
    };
    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {TeamData(data)}
        </Grid>
    );
};
export default Team;
